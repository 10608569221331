import React, { useRef } from 'react';
// import Faq from './faq/Faq';
import Contact from './Contact';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Carousel from './carousel';
import aboutimg from './imgs/our mission hindi (1).png';
import Example from './video'
import IncomeCalculator from './IncomeCalc';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';


const Eng = () => {

    const contactRef = useRef(null);

    const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    return (
        <div className='app'>

        <div className="apply-now">
        <button className='apply-btn' onClick={scrollToContact}>Know More!</button>
        </div>
        <div  className="email">
        <a href="mailto:jeevankiapadhapi@gmail.com">
        <button className='contact-btn'><EmailIcon/></button>
        </a>
        </div>
        <div className="whatsaap">
        <a href="https://wa.me/qr/5YVAJB25ILT6B1">
        <button className='contact-btn'><WhatsAppIcon/></button>
        </a>
        </div>

            <div className="slideshow">

            <Carousel />

            </div>



            <div className="start-now-container">

            <div className='supreme-box'>
            <div className="title-box">
                <h1>Our Mission</h1>
            </div>

            <div className="box">
                <div className="content-box">
                    <div className="content">
                    <img id='about-img' src={aboutimg} alt="" />
                    </div>
                </div>
            </div>
            </div>
            </div>


            <div className="video-container">

                

                

                <div className="video-supreme-box">

                <div className="video-title-box">
                    <h1>मेरा संदेश</h1>
                </div>



                <div className="videos">

                <iframe width="853" height="480" src="https://www.youtube.com/embed/FiJI5bENfm8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>



                </div>
                </div>









            <div className="video-container">

                

                

<div className="video-supreme-box">

<div className="video-title-box">
    <h1>Why join</h1>
</div>



<div className="videos">

<Example videoId='https://youtu.be/Eg8R6oV-4k0'/>

</div>


</div>
</div>


            <div className="start-now-container">

            <div className='supreme-box'>
            <div className="title-box">
                <h1>Calculate your Income</h1>
            </div>

            <div className="box">
                <div className="content-box">
                    <div className="content">
                    <IncomeCalculator/>
                    </div>
                </div>
            </div>
            </div>
            </div>



            <div className="start-now-container">

                <div className='supreme-box'>
                <div className="title-box">
                    <h1>Start Now!</h1>
                </div>

                <div className="box">
                    <div className="content-box">
                        <div className="content">
                        Become an LIC Agent!
                        <ul>
                            <li>India's largest Insurance company backs you</li>
                            <li>Huge potential in the market due to under-insurance</li>
                            <li>Unlimited earning opportunities</li>
                            <li>Help people insure</li>
                        </ul>
                        </div>
                    </div>
                </div>
                </div>
                </div>





                <div className="opportunity-container">

                <div className="supreme-box">
                
                <div className="opportinity-title-box">
                    <h1>Opportunity Starts here!</h1>
                </div>

                <div className="box">
                    <div className="content-box">
                        <div className="opportunity-content">
                        <div className="left-div">

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/hours.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            Flexible working hours
                            </div>
                            </div>

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/earnings.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            Unlimited earnings
                            </div>
                            </div>

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/sale.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            Attractive commissions on every sale
                            </div>
                            </div>

                        </div>
                        <div className="right-div">

                        <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/training.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            Free training and seminars
                            </div>
                            </div>

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/social.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            Social, personal and professional recognition
                            </div>
                            </div>

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/boss.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            Start your own Startup
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                </div>

                </div>
                <div className="enjoy-container">

                <div className="supreme-box">

                <div className="enjoy-title-box">
                    <h1>Enjoy Rewards!</h1>
                </div>

                <div className="enjoy-box">
                    <div className="content-box">
                        <div className="opportunity-content">
                        <div className="lenjoy-left-div">

                            <div className="enjoy-item">
                            
                            <div className="opportunity-text">
                            <h1>Sales Incentives</h1>

                            <ul>
                                    <li>First Commission</li> 
                                    <li>Renewal Commission</li>
                                    <li>Bonus Commission</li>
                                    <li>Hereditary Commission</li>
                                    <li>Competition Prizes</li>
                                </ul>

                                

                                

                                

                                

                                
                            </div>
                            </div>
                        </div>

                        <div className="enjoy-mid-div">

                        <div className="enjoy-item">
                        <div className="opportunity-text">

                                <h1>Special Benefits</h1>
                                <ul>
                                    <li>Gratuity</li> 
                                    <li>Term Insurance</li>
                                    <li>Group Insurance</li>
                                    <li>Medical Insurance</li>
                                    <li>Pension Scheme</li>
                                    <li>Group Personal Accident & Disability Scheme</li>
                                    <li>Sponsorship to National and International Conventions</li>
                                </ul>

                                
                            </div>
                            </div>
                        </div>

                        <div className="enjoy-right-div">

                        <div className="enjoy-item">
                        <div className="opportunity-text">
                                <h1>Allowances & Advances</h1> 

                                <li>Office Allowance</li> 
                                <li>Fringe Benefits</li>
                                <li>Various Advances for</li>
                                <li>Purchase of 2/4 wheelers</li>
                                <li>Laptop/Computer</li>
                                <li>Festival/Marriage</li>
                                <li>Office Equipment</li>
                                <li>Housing Loan at concessional rates</li>

                                    
                            </div>
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>

                </div>





                <div className="faq-container">
                <div className='supreme-box'>
                <div className="title-box">
                    <h1>FAQ</h1>
                </div>

                {/* <div className="box">
                    <div className="faq-box">
                    
                            <Faq/>
                            

                    </div>
                </div> */}
                </div>
                </div>

                <div className="startup-steps-container">
                <div className='supreme-box'>
                <div className="title-box">
                    <h1>Start-up in 5 easy Steps!</h1>
                </div>

                <div className="box">
                    <div className="content-box">
                        <div className="content">
                        <p>1. Fill in application form</p>
                        <p>2. Submit necessary documents like:- Photo, Age Proof, Address Proof, Qualification Certificate</p>
                        <p>3. Undergo preparatory training for appearing in the Insurance Agency Examination</p>
                        <p>4. Pass the Examination</p>
                        <p>5. Receive Letter of Appointment from LIC and become an LIC Agent</p>
                        </div>
                    </div>
                </div>

                

                </div>
                </div>



                <div className="contact-container" ref={contactRef}>
                <Contact/>
                </div>

                <div className="footer-container">

                <div className="footer-text">
                <h3 style={{color: 'white'}}>
                    Lal Kamleshwar Singh
                </h3>
                <p>(Coach for Ace LIC Advisor)</p>
                </div>



<div className="links">
                    <a href="https://www.instagram.com/bilaspur_startup/?igshid=ZDdkNTZiNTM%3D"><InstagramIcon/></a>
                    <a href="https://www.facebook.com/jeevankiapadhapi?mibextid=ZbWKwL"><FacebookIcon/></a>
                    <a href="https://twitter.com/lksingh23?s=08"><TwitterIcon/></a>
                    <a href="https://www.linkedin.com/in/lal-kamleshwar-singh-622aa1201/"><LinkedInIcon/></a>       
</div>

</div>
        </div>
    );
}

export default Eng;
