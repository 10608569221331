import React, { useRef } from 'react';
import Contact from './Contact';
import Carousel from './carousel';
// import FaqHindi from './faq/faqHindi';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import aboutimg from './imgs/mission banner (1).png';
import IncomeCalculator from './IncomeCalc';
import YouTube from 'react-youtube';
import Video from './video';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';


const Hin = () => {

    const contactRef = useRef(null);

        const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }


    return (
        <div className='app-hindi'>

        <div className="apply-now">
        <button className='apply-btn' onClick={scrollToContact} >Know More!</button>
        </div>
        <div className="email">
        <a href="mailto:jeevankiapadhapi@gmail.com">
        <button className='contact-btn'><EmailIcon/></button>
        </a>
        </div>
        <div className="whatsaap">
        <a href="https://wa.me/qr/5YVAJB25ILT6B1">
        <button className='contact-btn'><WhatsAppIcon/></button>
        </a>
        </div>

            <div className="slideshow">

            <Carousel />

            </div>

            <div className="start-now-container">

            <div className='supreme-box'>
            <div className="title-box">
                <h1>हमारा ध्येय</h1>
            </div>

            <div className="box">
                <div className="content-box">
                    <div className="content">
                    <img id='about-img' src={aboutimg} alt="" />
                    </div>
                </div>
            </div>
            </div>
            </div>

            <div className="video-container">

                

                

                <div className="video-supreme-box">

                <div className="video-title-box">
                    <h1>मेरा संदेश</h1>
                </div>



                <div className="videos">

                <iframe width="853" height="480" src="https://www.youtube.com/embed/FiJI5bENfm8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>

                

                </div>
                </div>








            <div className="video-container">

                

                

                <div className="video-supreme-box">

                <div className="video-title-box">
                    <h1>क्यों जुड़ें</h1>
                </div>



                <div className="videos">

                <Video/>

                </div>



                </div>
                </div>


                <div className="start-now-container">

                <div className='supreme-box'>
                <div className="title-box">
                    <h1>अपनी आमदनी जानें</h1>
                </div>

                <div className="box">
                    <div className="content-box">
                        <div className="content">
                        <IncomeCalculator/>
                        </div>
                    </div>
                </div>
                </div>
                </div>




            <div className="start-now-container" lang='hi'>

                <div className='supreme-box'>
                <div className="title-box">
                    <h1>अभी शुरू करें!</h1>
                </div>

                <div className="box">
                    <div className="content-box">
                        <div className="content"><h1>
                        Tech Insurance Advisor बने</h1>
                        <ul>
                            <li>भारत की सबसे बड़ी बीमा कंपनी (LIC of India) के साथ|</li>
                            <li>सीमित बीमा के कारण मार्केट में असीमित संभावनाएं</li>
                            <li>कमीशन आधारित असीमित कमाई के अवसर</li>
                            <li>लोगों को बीमा द्वारा सुरक्षित करने में सहायता करें</li>
                        </ul>
                        </div>
                    </div>
                </div>
                </div>
                </div>







                <div className="opportunity-container">

                <div className="supreme-box">
                
                <div className="opportinity-title-box">
                    <h1>आप पाएंगे</h1>
                </div>

                <div className="box">
                    <div className="content-box">
                        <div className="opportunity-content">
                        <div className="left-div">

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/hours.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            काम का मनचाहा समय
                            </div>
                            </div>

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/earnings.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            असीमित कमाई
                            </div>
                            </div>

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/sale.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            हर बिक्री पर आकर्षक कमिशन
                            </div>
                            </div>

                        </div>
                        <div className="right-div">

                        <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/training.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            मुफ्त प्रशिक्षण और सेमीनार
                            </div>
                            </div>

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/social.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            सामाजिक, व्यक्तिगत और व्यावसायिक पहचान
                            </div>
                            </div>

                            <div className="opportunity-item">
                            <img src="https://licindia.in/agent/assets/images/boss.png" className='opportunity-img' alt="" />
                            <div className="opportunity-text">
                            स्वयं का निवेश रहित व्यवसाय
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                </div>

                </div>
                <div className="enjoy-container">

                <div className="supreme-box">

                <div className="enjoy-title-box">
                    <h1>आपके लाभ!</h1>
                </div>

                <div className="enjoy-box">
                    <div className="content-box">
                        <div className="opportunity-content">
                        <div className="lenjoy-left-div">

                            <div className="enjoy-item">
                            
                            <div className="opportunity-text">
                            <h1>सेल्स इंसेंटिव्स</h1>

                            <ul>
                                    <li>पहला कमिशन</li> 
                                    <li>रिन्यूअल कमिशन</li>
                                    <li>बोनस कमिशन</li>
                                    <li>हेरेडिटरी कमिशन</li>
                                    <li>प्रतिस्पर्धाओं के इनाम</li>
                                </ul>

                                

                                

                                

                                

                                
                            </div>
                            </div>
                        </div>

                        <div className="enjoy-mid-div">

                        <div className="enjoy-item">
                        <div className="opportunity-text">

                                <h1>खास फायदें</h1>
                                <ul>
                                    <li>ग्रैच्युइटी</li> 
                                    <li>टर्म बीमा</li>
                                    <li>समूह बीमा</li>
                                    <li>मेडिकल बीमा</li>
                                    <li>पेंशन योजना</li>
                                    <li>समूह निजी दुर्घटना और विकलांगता योजना</li>
                                    <li>राष्ट्रीय और अंतर्राष्ट्रीय कंन्वेशन्स के लिए स्पॉन्सरशिप</li>
                                </ul>

                                
                            </div>
                            </div>
                        </div>

                        <div className="enjoy-right-div">

                        <div className="enjoy-item">
                        <div className="opportunity-text">
                                <h1>अलाउन्सेस और एडवांसेस</h1> 

                                <li>ऑफिस अलाउंस</li> 
                                <li>अनुषंगी लाभ</li>
                                <li>इनके लिए विभिन्न एडवांसेस</li>
                                <li>2/4 व्हीलर्स की खरीदारी</li>
                                <li>लैपटॉप/ कम्प्यूटर</li>
                                <li> त्यौहार/ विवाह</li>
                                <li>ऑफिस उपकरण</li>
                                <li>रिआयती दरों में हाउसिंग लोन</li>

                                    
                            </div>
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>

                </div>


                <div className="faq-container">
                <div className='supreme-box'>
                <div className="title-box">
                    <h1>आपके सवाल</h1>
                </div>

                {/* <div className="box">
                    <div className="faq-box">
                    
                            <FaqHindi/>
                            

                    </div>
                </div> */}
                </div>
                </div>

                <div className="startup-steps-container">
                <div className='supreme-box'>
                <div className="title-box">
                    <h1>भर्ती की प्रक्रिया!</h1>
                </div>

                <div className="box">
                    <div className="content-box">
                        <div className="content">
                        <p>1. आवेदन पत्र भरें</p>
                        <p>2. ज़रूरी कागज़ात जमा करें जैसे:- फोटो, उम्र का प्रमाण, पते का प्रमाण, पात्रता प्रमाणपत्र</p>
                        <p>3. बीमा संस्थान परीक्षा देने के लिए प्रारंभिक प्रशिक्षण प्राप्त करें</p>
                        <p>4. परीक्षा पास करें</p>
                        <p>5. एलआईसी की ओर से अपॉइंटमेंट लेटर प्राप्त करें और एलआईसी एजेंट बनें</p>
                        </div>
                    </div>
                </div>

                

                </div>


                </div>






                <div className="contact-container" ref={contactRef}>
                <Contact/>
                </div>

                <div className="footer-container">

                <div className="footer-text">
                <h3 style={{color: 'white'}}>
                    Lal Kamleshwar Singh
                </h3>
                <p>(Coach for Ace LIC Advisor)</p>
                </div>


                


                <div className="links">
                    <a href="https://www.instagram.com/bilaspur_startup/?igshid=ZDdkNTZiNTM%3D"><InstagramIcon/></a>
                    <a href="https://www.facebook.com/jeevankiapadhapi?mibextid=ZbWKwL"><FacebookIcon/></a>
                    <a href="https://twitter.com/lksingh23?s=08"><TwitterIcon/></a>
                    <a href="https://www.linkedin.com/in/lal-kamleshwar-singh-622aa1201/"><LinkedInIcon/></a>
                </div>

                </div>
        </div>
    );
}

export default Hin;