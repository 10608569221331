
import React from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from "prop-types"



const Video = ({embedId}) => {

    
    Video.propTypes = {
        embedId: PropTypes.string.isRequired
    };



    return (
        <div>
        <Container>
        <div>
        <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/sj9jciLL_t0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
    />
        </div>
        </Container>

        </div>
    );









}

export default Video;

