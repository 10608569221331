import React, { useState } from 'react';
import './IncomeCalc.css'

function IncomeCalculator() {
    const [numMeetings, setNumMeetings] = useState(0);
    const [numConvinced, setNumConvinced] = useState(0);
    const [income, setIncome] = useState(0);

    const handleNumMeetingsChange = (event) => {
        setNumMeetings(parseInt(event.target.value));
    };

    const handleNumConvincedChange = (event) => {
        setNumConvinced(parseInt(event.target.value));
    };

    const handleCalculateIncome = () => {
        const income = (numMeetings*260) * (numConvinced/10 * 4000)
        setIncome(income);
    };

    return (
        <div>
        <section id='income-calculator'>
        <label htmlFor="numMeetings"><h2> आप प्रति दिन कितने लोगों से मिल सकते हैं?</h2> </label>
        <input
            type="number"
            id="numMeetings"
            name="numMeetings"
            value={numMeetings}
            onChange={handleNumMeetingsChange}
        />
        <br />
        <label htmlFor="numConvinced"><h2> आप 10 में से कितने लोगों को पॉलिसी के लिए राजी कर सकते हैं?</h2></label>
        <input
            type="number"
            id="numConvinced"
            name="numConvinced"
            value={numConvinced}
            onChange={handleNumConvincedChange}
            
        />
        <br />
        <button className='calc-btn' onClick={handleCalculateIncome}>Calculate</button>
        <br />
        <label> आपकी वार्षिक अनुमानित आय <h1>{income + ' ₹'}</h1></label>
        <p>(कार्य अवधि: सप्ताह में पांच दिन)</p>
        </section>
        </div>
    );
}

export default IncomeCalculator;



