
import { useRef, useState } from 'react';
import './App.css';
import logo from './imgs/final-logo.jpeg'
import Eng from './Eng';
import Hin from './Hin';






function App() {


  const [Lang, setLang] = useState(true);

  const handleEvent = ()=>{
    setLang(!Lang);
  }






  return (


    <div className="App" lang='hi'>
    
      
    
      

      <div className="header">
      <nav>

      <div className="name-head">
      <h1>Start Your Own Startup</h1>
      <p>(Guaranteed Loss Free Business)</p>
      </div>
      

        <div className="logo">
          <img id='logo' src={logo} alt="" href={App.js} />
          
        </div>
        <div className="name-head">
      <h1>Lal Kamleshwar Singh</h1>
      <p>(Coach for Ace LIC Advisor)</p>
      </div>
        
        <button className='lang-btn' onClick={handleEvent}>{Lang? 'हिंदी': 'English'}</button>
        </nav>
      </div>

      



      {Lang? <Hin/>: <Eng/> }

    


    
      </div>
);


  






}

export default App;
