import React, { useState, useEffect } from 'react';


    const images = [
    { id: 1, url: '/think big act smart (1640 × 624 px).png' },
    { id: 2, url: '/financial freedom Facebook Cover (1640 × 622 px).png' },
    { id: 3, url: 'https://static.wixstatic.com/media/db0faa_5498c69fd0c94f55abf0ac767c5f942e~mv2.png/v1/fill/w_2240,h_1138,al_c,q_95,enc_auto/db0faa_5498c69fd0c94f55abf0ac767c5f942e~mv2.png' },
    { id: 4, url: '/Yellow Online Business Facebook Cover (1640 × 622 px).png' },
    ];

    const Carousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setCurrentSlide((currentSlide + 1) % images.length);
        }, 10000);
        return () => clearInterval(intervalId);
    }, [currentSlide]);

    const handlePrevSlide = () => {
        setCurrentSlide((currentSlide - 1 + images.length) % images.length);
    };

    const handleNextSlide = () => {
        setCurrentSlide((currentSlide + 1) % images.length);
    };

    return (
        <div className="carousel-container">
        <div className="carousel-slide">
            {images.map((image, index) => (
            <div
                key={image.id}
                className={index === currentSlide ? 'slide active' : 'slide'}
            >
                <img src={image.url} alt={`Slide ${index}`} />
            </div>
            ))}
        </div>
        <button className="prev" onClick={handlePrevSlide}>
            &#10094;
        </button>
        <button className="next" onClick={handleNextSlide}>
            &#10095;
        </button>
        </div>
    );
    };

export default Carousel;
