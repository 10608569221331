import React from 'react';
import './Contact.css'


const Contact = () => {
    return (
        <div>
        <h1>Contact Now</h1>

        <div className="contact-content">
        <a href='https://surveyheart.com/form/5f8c45657692d01432d3f5d4'>
        <button className='form-btn'> Registration Form</button></a>
        </div>
        </div>
    );
}

export default Contact;
